import React, { Fragment, useState, useEffect } from "react";
import styles from "./BeckPlan.module.css";

const DISPLAY_VERTICAL = 1140
const BASE_ANIM_DELAY = .3

// Up to 6 steps will work
const data = [
    {
        header: 'Diagnose',
        body: 'Every potential student will take an ACT and SAT diagnostic before enrollment in order to decide which exam to pursue.'
    },{
        header: 'Consult',
        body: 'We meet with our parents and students to discuss the student\'s diagnostic results, create a tutoring and testing plan, and provide college guidance.'
    },{
        header: 'Evaluate',
        body: 'Student applies instruction to problems associated with the subject matter taught in the lesson to confirm student\'s comprehension.'
    },{
        header: 'Instruct',
        body: 'Teach students concepts (in addition to tips and tricks) associated with the areas of deficiency.'
    },{
        header: 'Communicate',
        body: 'After each lesson, the Beck tutor provides classwork & homework results as well as praises and concerns associated with the student\'s progress.'
    }
]

const FourStepPlan = () => {
    const [vertical, setVertical] = useState(false)

    let animated = false

    useEffect(() => {
        // Initialize display mode (vertical)
        setDisplayMode()

        window.addEventListener('resize', setDisplayMode, { passive: true })
        document.addEventListener('scroll', handleAnimation, { passive: true })

        return function cleanup() {
            window.removeEventListener('resize', setDisplayMode, { passive: true })
            document.removeEventListener('scroll', handleAnimation, { passive: true })
        }
    }, [])

    function setDisplayMode (event) {
        // Had to use window.innerWidth since it matches up with media query width
        if (window.innerWidth <= DISPLAY_VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
    }

    // Animation should only occur if the component is being displayed horizontally
    function handleAnimation(event) {
        if (!vertical && !animated && document.getElementById("animateElement")) {
            let element = document.getElementById("animateElement")
            let rect = element.getBoundingClientRect()
            let elemTop = rect.top
            let elemBottom = rect.bottom

            let elements = document.getElementsByName("toAnimate")

            if (elemTop < window.innerHeight && elemBottom >= 0) {
                for (let i = 0; i < elements.length; i++) {
                    elements[i].classList.add("animate__animated", "animate__fadeIn")
                }
            }
        }
    }

    return (
        <>
            <div className={`w-100`}>
                {!vertical ? (
                    <div className='d-flex flex-column align-items-center'>
                        {/* Render first row of steps */}
                        <div className='d-flex justify-content-center align-items-center' style={{ marginBottom: '5vh' }}>
                            {data.map((content, i) => {
                                if (i <= 2) {
                                    return (
                                        <Fragment key={'contentPlan' + i}>
                                            <div name="toAnimate" className={styles.dividerV} style={{ animationDelay:`${(BASE_ANIM_DELAY * i)}s` }}/>
                                            <div id="animateElement" name="toAnimate" className={`d-flex align-items-center ${styles.cardH}`} style={{ animationDelay:`${(BASE_ANIM_DELAY * i)}s` }}>
                                                <div className={styles.bigNumber} style={{ marginRight: i === 0 && '6px' }}>
                                                    {i + 1}
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <div className={styles.header}>{content.header}</div>
                                                    <p className={styles.text}>
                                                        {content.body}
                                                    </p>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                }
                            })}
                            <div name="toAnimate" className={styles.dividerV} style={{ animationDelay:`${(BASE_ANIM_DELAY * 3)}s` }}/>
                        </div>
                        {/* Render second row of steps */}
                        <div className='d-flex justify-content-center align-items-center'>
                            {data.map((content, i) => {
                                if (i > 2) {
                                    return (
                                        <Fragment key={'contentPlan' + i}>
                                            <div name="toAnimate" className={styles.dividerV} style={{ animationDelay:`${(BASE_ANIM_DELAY * (i + 1))}s` }}/>
                                            <div id="animateElement" name="toAnimate" className={`d-flex align-items-center ${styles.cardH}`} style={{ animationDelay:`${(BASE_ANIM_DELAY * (i + 1))}s` }}>
                                                <div className={styles.bigNumber}>
                                                    {i + 1}
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <div className={styles.header}>{content.header}</div>
                                                    <p className={styles.text}>
                                                        {content.body}
                                                    </p>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                }
                            })}
                            <div name="toAnimate" className={styles.dividerV} style={{ animationDelay:`${(BASE_ANIM_DELAY * 6)}s` }}/>
                        </div>
                    </div>
                ):(
                    <div className='d-flex flex-column align-items-center'>
                        {data.map((content, i) => {
                            return (
                                <Fragment key={'contentPlan' + i}>
                                    <div id="animateElement" name="toAnimate" className={`d-flex align-items-center ${styles.cardV}`} style={{ animationDelay:`${(BASE_ANIM_DELAY * i)}s` }}>
                                        <div className={styles.bigNumber}>
                                            {i + 1}
                                        </div>
                                        <div className="d-flex flex-column">
                                            <div className={styles.header}>{content.header}</div>
                                            <p className={styles.text}>
                                                {content.body}
                                            </p>
                                        </div>
                                    </div>
                                    {i !== data.length - 1 && (
                                        <div name="toAnimate" className={styles.dividerH} style={{ animationDelay:`${(BASE_ANIM_DELAY * i)}s` }}/>
                                    )}
                                </Fragment>
                            )
                        })}
                    </div>
                )}
            </div>
        </>
    )
}

export default FourStepPlan