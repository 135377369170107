import React, { useEffect, useState, useRef } from 'react';
import styles from "./CollegeAcceptance.module.css"
import { Swipeable } from 'react-swipeable'
import { isElementInViewport } from '../../../utility/general'

const VERTICAL = 767
const TIMER = 15000
const SELECTION_DELAY = 500 // Delay to allow fade animation to play to completion
const TEXT_ANIM_DURATION = 25

const AdvisingContainer = ({ students, uniqueID }) => {
    const [vertical, setVertical] = useState(false)
    const [selected, setSelected] = useState(0)
    const [showImage, setShowImage] = useState(true)
    const [triggerInterval, setTriggerInterval] = useState(false)

    let myInterval = useRef()
    let textIndex = useRef()
    let stopText = useRef()
    let initial = useRef(true)

	useEffect(() => {
        // Initialize display mode (vertical)
        setDisplayVertical()

        // Pre-load images when component mounts
        students.forEach(student => {
            const image = new Image()
            image.src = student.image
        })

        window.addEventListener('resize', setDisplayVertical, { passive: true })
        window.addEventListener('scroll', beginInterval, { passive: true })

        // This is only used when the components is unmounted
        return function cleanup() {
            window.removeEventListener('resize', setDisplayVertical, { passive: true })
            window.removeEventListener('scroll', beginInterval, { passive: true })
            clearInterval(myInterval.current)
		}
    }, [])

    useEffect(() => {
        // Make image fade in
        setShowImage(true)

        // Interval and text animation functionality should only happen if this component has come into view at least once
        if (triggerInterval) {
            // Stop timer
            clearInterval(myInterval.current)

            // Restart timer
            myInterval.current = setInterval(() => {
                setTimeout(() => {
                    setSelected(selected => (selected + 1) % students.length)
                }, SELECTION_DELAY)
                
                setShowImage(false)
            }, TIMER)

            setText()
        }
    }, [selected])

    useEffect(() => {
        if (triggerInterval && initial.current === true) {
            initial.current = false

            myInterval.current = setInterval(() => {
                setTimeout(() => {
                    setSelected(selected => (selected + 1) % students.length)
                }, SELECTION_DELAY)

                setShowImage(false)
            }, TIMER)

            // Set text
            setText()
        }
    }, [triggerInterval])

    // Trigger interval
    function beginInterval() {
        let thisElement = document.getElementById("collegeAcceptanceComp" + uniqueID)

        if (thisElement) {
            const inView = isElementInViewport(thisElement, thisElement.clientHeight)

            if (inView) {
                setTriggerInterval(true)
            }
        }
    }
    
    // Recursive function to create text animation
    function typeWriter() {
        let txt = students[selected].body
            
        if (document.getElementById("studentText" + uniqueID) && !stopText.current) {
            if (textIndex.current < txt.length) {
                document.getElementById("studentText" + uniqueID).innerHTML += txt.charAt(textIndex.current)
                textIndex.current++
                setTimeout(typeWriter, TEXT_ANIM_DURATION)
            }
        }
    }
	
	function setDisplayVertical(event) {
        if (window.innerWidth <= VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
    }

    function setText() {
        document.getElementById("studentText" + uniqueID).innerHTML = ''
        textIndex.current = 0
        stopText.current = true

        setTimeout(() => {
            stopText.current = false
            typeWriter()
        }, 100)
    }

	return (
        <div className="d-flex flex-column">
            <Swipeable onSwiped={(event) => {
                if (event.dir === "Left") {
                    setTimeout(() => {
                        setSelected(selected => (selected + 1) % students.length)
                    }, SELECTION_DELAY)
                    
                    setShowImage(false)
                } else if (event.dir === "Right") {
                    setTimeout(() => {
                        setSelected(selected => selected <= 0 ? students.length - 1 : (selected - 1) % students.length)
                    }, SELECTION_DELAY)
                    
                    setShowImage(false)
                }
            }}>
                <div id={"collegeAcceptanceComp" + uniqueID} className="d-flex justify-content-center">
                    <div className={`${styles.container} d-flex ${vertical ? "flex-column" : ""} align-items-center justify-content-center`} style={{ zIndex: "1" }}>
                        <div className={`${styles.imageHolder} d-flex justify-content-center`}>
                            <div 
                                id={"collegeAcceptImage" + uniqueID}
                                style={{
                                    backgroundImage: `url(${students[selected].image})`,
                                    transition: `opacity ${(SELECTION_DELAY / 1000) - .05}s`
                                }}
                                className={`${styles.image} ${showImage && styles.imageShow}`}
                            />
                            <div className={`d-flex ${styles.buttonContainer}`}>
                                {students.map((student, i) => {
                                    let display = ""

                                    if (selected === i) {
                                        display = styles.focusButton
                                    }

                                    return (
                                        <div
                                            key={"button" + i}
                                            onClick={() => {
                                                //Set selected to new field
                                                setTimeout(() => {
                                                    setSelected(i)
                                                }, SELECTION_DELAY)
                                                
                                                setShowImage(false)
                                            }}
                                            className={`${styles.button} ${display}`}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                        <div className={`${styles.cardBody} d-flex flex-column ${vertical ? 'justify-content-between' : 'justify-content-center'}`}>
                            {/* <p className={styles.body}>{students[selected].body}</p> */}
                            {/* <div style={{ height: '300px', lineHeight: '300px' }} className="d-flex align-items-center">
                                <p id={"studentText" + uniqueID} style={{ lineHeight: '1.5', verticalAlign: 'middle', display: 'inline-block' }} className={styles.body}></p>
                            </div> */}
                            <p id={"studentText" + uniqueID} className={styles.body}></p>
                            {/* {students[selected].year && (
                                <div className="w-100 d-flex justify-content-end" style={{ marginTop: "20px" }}>
                                    <label style={{ opacity: showImage ? 1 : 0, transition: `opacity ${(SELECTION_DELAY / 1000) - .05}s` }} className={styles.year}>Class of {students[selected].year}</label>
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
            </Swipeable>
        </div>
    )
}

export default AdvisingContainer;