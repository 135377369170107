import React from 'react';
import "../styles/global.css";

import Hero from '../components/website_components/Hero/Hero'
// import Hero2 from '../components/website_components/Hero2/Hero2'
import Navbar from '../components/website_components/Navbar/Navbar';
import Footer from '../components/website_components/Footer/Footer';

import SEO from '../components/SEO';

const MainLayout = ({ children, seo }) => (
  <>
    <SEO {...seo}/>
    <Navbar />
    <Hero />
    
    <div className="bcp page">
      {children}
    </div>
    <Footer />
  </>
);

export default MainLayout;







