import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styles from './CallToAction.module.css'
import { IoIosArrowForward } from 'react-icons/io'

const SHOW = 1 // Based on percent height of viewport
const HIDE = .1

const componentStyle = {
    red: {
        background: '#D73337',
        circle: '#ffffff',
        text: '#ffffff',
        arrow: '#D73337'
    },
    white: {
        background: '#ffffff',
        circle: '#D73337',
        text: '#D73337',
        arrow: '#ffffff'
    }
}

const CallToAction = ({ link, text, style }) => {
    const [show, setShow] = useState(false)

    let thisComponent = useRef()

    useEffect(() => {
        window.addEventListener('scroll', handleShow, { passive: true })

        // This is only used when the component is unmounted
        return function cleanup() {
            window.removeEventListener('scroll', handleShow, { passive: true })
        }
    }, [])

    function handleShow() {
        if ((thisComponent.current.getBoundingClientRect().top <= document.documentElement.clientHeight * SHOW) && (thisComponent.current.getBoundingClientRect().top >= 0 - document.documentElement.clientHeight * HIDE)) {
            setShow(true)
        } else {
            setShow(false)
        }
    }

    return (
        <div className='w-100'>
            <div className={styles.centeredContainer}>
                <div ref={thisComponent} className={styles.staticGeneral + " " + (!show && styles.staticOffScreen)}>
                    <Link to={link} style={{ textDecoration: 'none' }} className="gtm-cta">
                        <div className={styles.cardBody + " " + styles.staticCardBody + " d-flex align-items-center"} style={{ backgroundColor: componentStyle[style].background }}>
                            <div className={styles.circleContainer + " d-flex align-items-center"}>
                                <div className={styles.circle + " d-flex align-items-center justify-content-center"} style={{ backgroundColor: componentStyle[style].circle }}>
                                    <div className={styles.arrow + " d-flex align-items-center"} style={{ color: componentStyle[style].arrow }}>
                                        <IoIosArrowForward />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center" style={{ height: '66px', width: '76px' }}>
                                <label className={styles.text} style={{ color: componentStyle[style].text }}>{text}</label>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CallToAction;