import React, { useEffect, useState } from 'react';
import styles from "./BigSpeed.module.css"
import DynamicSpeed from '../DynamicSpeedometer/DynamicSpeed'
import { isElementInViewport } from '../../../utility/general'

const VERTICAL = 1000
const SMALL_GRAPHIC = 520
const ANIMATION_DELAY = 300
const ANIMATION_SPEED = .35
const FRACTION_TO_ANIM = .4

// SAT Speedometer data
const SAT_BEFORE = 1130
const SAT_AFTER = 1500
const SAT_TOTAL = 1600

// ACT Speedometer data
const ACT_BEFORE = 22
const ACT_AFTER = 32
const ACT_TOTAL = 36

// SAT Speedometer colors
const SAT_COLOR_ONE = "#FF1010"
const SAT_COLOR_TWO = "#F66E1C"

// ACT Speedometer colors
const ACT_COLOR_ONE = "#FF3D00"
const ACT_COLOR_TWO = "#FFA800"

const dataSAT = {
    circles: [
        {
            // fractionFill: (SAT_BEFORE / SAT_TOTAL)
            fractionFill: 0
        }
    ],
    transitionTime: 1
}

const dataACT = {
    circles: [
        {
            // fractionFill: (ACT_BEFORE / ACT_TOTAL)
            fractionFill: 0
        }
    ],
    transitionTime: 1
}

const centerContentSAT = (
    <>
        <label className={styles.centerContentNumber}>+{SAT_AFTER - SAT_BEFORE}</label>
        <label className={styles.centerContentText}>Point Increase</label>
    </>
)

const centerContentACT = (
    <>
        <label className={styles.centerContentNumber}>+{ACT_AFTER - ACT_BEFORE}</label>
        <label className={styles.centerContentText}>Point Increase</label>
    </>
)

const BigSpeed = () => {
    const [vertical, setVertical] = useState(false)
    const [small, setSmall] = useState(false)
    const [visibleSAT, setVisibleSAT] = useState(false)
    const [visibleACT, setVisibleACT] = useState(false)
    const [circleDataSAT, setcircleDataSAT] = useState(dataSAT)
    const [circleDataACT, setcircleDataACT] = useState(dataACT)

    // Used to prevent extra rerenders of speedometers when visibility of elements has not changed
    let prevSAT = false
    let prevACT = false

    useEffect(() => {
        // Initialize display mode (vertical)
        setDisplayMode()
        // Initialize svg size (small)
        setGraphicSize()

        window.addEventListener('resize', setDisplayMode, { passive: true })
        window.addEventListener('resize', setGraphicSize, { passive: true })
        window.addEventListener('scroll', animateOnVisible, { passive: true })

        return function cleanup() {
            window.removeEventListener('resize', setDisplayMode, { passive: true })
            window.removeEventListener('resize', setGraphicSize, { passive: true })
            window.removeEventListener('scroll', animateOnVisible, { passive: true })
        };
    }, [])

    function setDisplayMode(event) {
        if (window.innerWidth <= VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
    }

    function setGraphicSize(event) {
        if (document.documentElement.clientWidth <= SMALL_GRAPHIC) {
            setSmall(true)
        } else {
            setSmall(false)
        }
    }

    function calculateFillData(data, fill) {
        let tempData = { ...data }

        // Calculate transition time
        const distance = Math.abs(tempData.circles[0].fractionFill - fill)
        const time = distance / ANIMATION_SPEED

        tempData.circles[0].fractionFill = fill
        tempData.transitionTime = time

        return tempData
    }

    function animateOnVisible() {
        let satElement = document.getElementById("speedSAT")
        let actElement = document.getElementById("speedACT")

        if (satElement) {
            const inView = isElementInViewport(satElement, satElement.clientHeight * FRACTION_TO_ANIM)

            // Only take action if there has been a change in visibilty of the element
            if (inView !== prevSAT) {
                if (inView) {
                    // In view
                    prevSAT = true
    
                    setTimeout(() => {
                        const data = calculateFillData(circleDataSAT, SAT_AFTER / SAT_TOTAL)
                        setcircleDataSAT(data)
    
                        setVisibleSAT(true)
                    }, ANIMATION_DELAY)
                } else {
                    // Out of view
                    prevSAT = false
    
                    setTimeout(() => {
                        // const data = calculateFillData(circleDataSAT, SAT_BEFORE / SAT_TOTAL)
                        const data = calculateFillData(circleDataSAT, 0)
                        setcircleDataSAT(data)
    
                        setVisibleSAT(false)
                    }, ANIMATION_DELAY)
                }
            }
        }

        if (actElement) {
            const inView = isElementInViewport(actElement, actElement.clientHeight * FRACTION_TO_ANIM)

            // Only take action if there has been a change in visibilty of the element
            if (inView !== prevACT) {
                if (inView) {
                    // In view
                    prevACT = true
    
                    setTimeout(() => {
                        const data = calculateFillData(circleDataACT, ACT_AFTER / ACT_TOTAL)
                        setcircleDataACT(data)
    
                        setVisibleACT(true)
                    }, ANIMATION_DELAY)
                } else {
                    // Out of view
                    prevACT = false
    
                    setTimeout(() => {
                        // const data = calculateFillData(circleDataACT, ACT_BEFORE / ACT_TOTAL)
                        const data = calculateFillData(circleDataACT, 0)
                        setcircleDataACT(data)
    
                        setVisibleACT(false)
                    }, ANIMATION_DELAY)
                }
            }
        }
    }

    return (
        <div className="d-flex flex-column">
            <div id="speedSAT" className={`d-flex align-items-center ${vertical && 'flex-column'}`}>
                {vertical ? (
                    <>
                        <div className="d-flex flex-column align-items-center" style={{ marginLeft: !vertical ? "36px" : '' }}>
                            <h2 className={styles.heading}>SAT Score Improvements</h2>
                            <div className="d-flex justify-content-around w-100">
                                <label className={styles.label}>Before: <span className={styles.numberLabel}>{SAT_BEFORE}</span></label>
                                <label className={styles.label}>After: <span className={styles.numberLabel}>{SAT_AFTER}</span></label>
                            </div>
                        </div>
                        <div className={styles.speedContainer}>
                            <DynamicSpeed uniqueId={"1"} data={circleDataSAT} size={!small ? '250px' : '220px'} centerContent={centerContentSAT} showCenterContent={visibleSAT} gradientColor={{ start: SAT_COLOR_ONE, end: SAT_COLOR_TWO }}/>
                            <label className={styles.speedLabel}>Based on 1600 Point Total</label>
                        </div>
                    </>
                ):(
                    <>
                        <div className={styles.speedContainer}>
                            <DynamicSpeed uniqueId={"2"} data={circleDataSAT} size={!small ? '250px' : '220px'} centerContent={centerContentSAT} showCenterContent={visibleSAT} gradientColor={{ start: SAT_COLOR_ONE, end: SAT_COLOR_TWO }}/>
                            <label className={styles.speedLabel}>Based on 1600 Point Total</label>
                        </div>
                        <div className="d-flex flex-column align-items-center" style={{ marginLeft: !vertical ? "36px" : '' }}>
                            <h2 className={styles.heading}>SAT Score Improvements</h2>
                            <div className="d-flex justify-content-around w-100">
                                <label className={styles.label}>Before: <span className={styles.numberLabel}>{SAT_BEFORE}</span></label>
                                <label className={styles.label}>After: <span className={styles.numberLabel}>{SAT_AFTER}</span></label>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div id="speedACT" className={`d-flex align-items-center ${vertical && 'flex-column'}`} style={{ marginTop: vertical ? '84px' : '20px' }}>
                <div className="d-flex flex-column align-items-center" style={{ marginRight: !vertical ? "36px" : '' }}>
                    <h2 className={styles.heading}>ACT Score Improvements</h2>
                    <div className="d-flex justify-content-around w-100">
                        <label className={styles.label}>Before: <span className={styles.numberLabel}>{ACT_BEFORE}</span></label>
                        <label className={styles.label}>After: <span className={styles.numberLabel}>{ACT_AFTER}</span></label>
                    </div>
                </div>
                <div className={styles.speedContainer}>
                <DynamicSpeed uniqueId={"3"} data={circleDataACT} size={!small ? '250px' : '220px'} centerContent={centerContentACT} showCenterContent={visibleACT} gradientColor={{ start: ACT_COLOR_ONE, end: ACT_COLOR_TWO }}/>
                    <label className={styles.speedLabel}>Based on 36 Point Total</label>
                </div>
            </div>
        </div>
    )
}

export default BigSpeed;