import React, { useEffect, useState } from 'react';
import styles from "./AdvisingContainer.module.css"
import image1 from "../../../images/advising/pic1.jpg"
import image2 from "../../../images/advising/pic2.jpg"
import image3 from "../../../images/advising/pic3.jpg"
import { FaCircle } from 'react-icons/fa'

const VERTICAL = 767;
const MARGIN_BETWEEN = "80px"

let boundings = []

const content = [{
	image: image1,
	header: "Advising",
	body: [
		"Analyze the student's academic transcripts, test scores, extracurricular activities, and learning style",
		"Create a plan to gain the intended college acceptance / admission",
		"Provide recommendations to correct any profile decificiences",
		"Advise on coure scheduling, community serice projects, summer experiences (internships, college programs, etc.)"
	]
},{
	image: image2,
	header: "College Essay Help",
	body: [
		"Brainstorm, Edit, Revise, and Review the Following:",
		"Common Application and Coalition Main Essay and Supplements",
		"Activities Resume",
		"The Entire College Application",
		"Extenuating Circumstances Addendum"
	]
},{
	image: image3,
	header: "Scholarship Analysis",
	body: [
		"Conduct Searches for applicable scholarships",
		"Brainstorm, critique, and edit scholarship essays"
	]
}]

const AdvisingContainer = () => {
	const [vertical, setVertical] = useState(false)

	useEffect(() => {
        // Initialize display mode (vertical)
		setDisplayVertical()
		
		window.addEventListener('resize', setDisplayVertical, { passive: true })

        // This is only used when the components is unmounted
        return function cleanup() {
			window.removeEventListener('resize', setDisplayVertical, { passive: true })
		}
    }, [])
	
	function setDisplayVertical(event) {
        if (window.innerWidth <= VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
	}

	return (
		<div id='advice' style={{ zIndex: 1 }} className="d-flex flex-column align-items-center">
			{ content.map((card, i) => {
				if (i % 2 === 0 || vertical) { // Picture on the left
					return (
						<div key={"advice" + i} className='w-100'>
							<div className={`${styles.container} d-flex ${vertical ? "flex-column" : ""} align-items-center justify-content-center`} style={{ marginBottom: MARGIN_BETWEEN }}>
								<div className={styles.imageHolder}>
									<div style={{
										backgroundImage: `url(${card.image})`
									}}
									className={styles.image + " " + (i === 1 && styles.imageAdjust)}/>
								</div>
								<div className={`${styles.cardBody} d-flex flex-column justify-content-center`} style={{ paddingLeft: "70px" }}>
									<h4 className={styles.heading}>{card.header}</h4>
									{card.body.map((text, i) => {
										return (
											<div key={card.header + i}>
												<div className="d-flex">
													<FaCircle className={styles.circle}/><p className={styles.body}>{text}</p>
												</div>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					)
				} else { // Picture on the right
					return (
						<div key={"advice" + i} className='w-100'>
							<div className={`${styles.container} d-flex ${vertical ? "flex-column" : ""} align-items-center justify-content-center`} style={{ marginBottom: MARGIN_BETWEEN }}>
								<div className={`${styles.cardBody} d-flex flex-column justify-content-center`} style={{ paddingRight: "70px" }}>
									<h4 className={styles.heading}>{card.header}</h4>
									{card.body.map((text, i) => {
										return (
											<div key={card.header + i}>
												<div className="d-flex">
													<FaCircle className={styles.circle}/><p className={styles.body}>{text}</p>
												</div>
											</div>
										)
									})}
								</div>
								<div className={styles.imageHolder}>
									<div style={{
										backgroundImage: `url(${card.image})`
									}}
									className={styles.image + " " + styles.imageRight}/>
								</div>
							</div>
						</div>
					)
				}
			})}
		</div>
	)
}

export default AdvisingContainer;