import student1 from '../images/testprep/student1.jpg'
import student2 from '../images/testprep/student2.jpg'
import student3 from '../images/testprep/student3.jpg'
import student4 from "../images/collegeAcceptance/pic1.jpg"
import student5 from "../images/collegeAcceptance/pic2.jpg"
import student6 from "../images/collegeAcceptance/pic3.jpg"

export const students1 = [{
    image: student5,
    header: "Daniela Zuberan",
    body: "Beck College Prep saw my potential before I could acknowledge it within myself. They did not allow my initial test score to define me; instead, they worked tirelessly to remind me that I not only had the academic potential but the work ethic to achieve any of my academic dreams. I am thankful for their effort and dedication: they always have a recommendation with me.",
    year: '2024'
},{
    image: student6,
    header: "Leo Brito",
    body: "The Beck College Prep program provided me the flexibility of managing my tutoring and studying for the SAT and ACT without interrupting my dedication to water polo. Beck College Prep doesn’t only have one method of reaching students but really understands the priorities of each student. Through their understanding, they are able to communicate their logic in a way that inspires students to achieve their goals. Trust me it was not easy but the juice was worth the squeeze.",
    year: '2024'
},{
    image: student3,
    header: "Zion Moss",
    body: "Beck College Prep provided me with an assertive and objective tutoring plan that allowed me to tackle my deficiencies in much less time. Not only was it entertaining but, even in a group setting, I felt that my needs were still taken care of, because Beck College Prep Instructors take the time to meet with every single student before they even begin the program. I appreciate all of their help and would reccomend them to anyone",
    year: '2024'
}]

export const students2 = [
//     {
// 	image: student4,
// 	header: "Sabrina Mas",
// 	body: "Bacon ipsum dolor amet nisi ad dolore biltong, qui pastrami jowl rump buffalo. Ham hock. Doner ut drumstick do dolore est pork belly fatback id pancetta. Irure cupim tongue elit tenderloin chislic tri-tip corned beef shankle ham hock fatback consequat anim turducken. Kielbasa nostrud ham nulla, nisi ullamco picanha pariatur tongue burgdoggen ground round quis t-bone ad commodo. Laborum short loin strip steak tail. Filet mignon sausage nulla bacon kevin short ribs."
// },
{
    image: student1,
    header: "Salvador Blanco",
    body: "My tutor Mercy not only mentored me in the test prep and college advising process but she also took the time to know me personally. Knowing that everything at Beck was personalized to my needs really motivated me to endure all of the hard work that they impressed upon me.",
    year: '2024'
},{
    image: student2,
    header: "Carly Martinez",
    body: " Beck helped me with more than a test score. I made meaningful connections in my community, gathered work experience (as I became a Beck intern after being a student) and what unparalleled dedication really means.",
    year: '2024'
}]

    