import React, { useState, useEffect } from "react";
import "../styles/global.css";
import '../styles/bootstrapOverride.css'
import { animateGeneral } from '../utility/general'
import AdvisingContainer from '../components/website_components/AdvisingContainer/AdvisingContainer.js';
import Layout from "../layouts/MainLayout";
import SubjectsContainer from "../components/website_components/SubjectsContainer/SubjectsContainer";
import BigSpeed from '../components/website_components/BigSpeedometerDisplay/BigSpeeds'
import CallToAction from '../components/website_components/CallToAction/CallToAction'
import ActionButton from '../components/website_components/CallToAction/ActionButton'
import styles from "../styles/index.module.css"
import CollegeAcceptance from '../components/website_components/CollegeAcceptance/CollegeAcceptance'
import BeckPlan from '../components/website_components/BeckPlan/BeckPlan'
import { students1, students2 } from '../data/collegeAcceptance'

const ACTION_DISPLAY = 1400
const ANIM_STOP = 780
const ACTION_TEXT_CONSULT = 'Schedule Consultation'
const ACTION_TEXT_TUTORING = 'Get Started'
const ACTION_TEXT_TESTING = 'Schedule Diagnostic'
const ACTION_TEXT_ADVISE = 'Get Started'

let boundings = [];

function _setSmoothScrollOnLinkClick() {
    let anchors = document.querySelectorAll('a[href^="#"]');

    for (let i = 0; i < anchors.length; i++) {
        const anchor = anchors[i];

        anchor.addEventListener("click", function (e) {
            e.preventDefault();

            const id = this.getAttribute("href").split("#")[1];
            const element = document.getElementById(id);
            const y = element.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({ top: y, behavior: 'smooth' })
        }, { passive: true });
    }
}


function animateListener() { animateGeneral('anim-flag', boundings); }

const IndexPage = () => {
    const [actionContent, setActionContent] = useState(null)
    const [actionConsultation, setActionConsultation] = useState(null)
    const [actionDisplay, setActionDisplay] = useState(false) // Mobile Display: true
    const [animStopped, setAnimStop] = useState(false)
    
    // Hook that calls the animation function when you scroll
    useEffect(() => {
        // Initialize display mode (actionDisplay)
        setDisplayModes()

        // Initialize call to action data for consultation section
        setActionConsultation([
            {
                position: document.getElementById('consultation-start'),
                text: ACTION_TEXT_CONSULT,
                style: 'red',
                link: '/contact',
                offsetStart: .35,
                offsetEnd: .8,
                bodyOffset: 20
            }
        ])

        // Initialize call to action data for general page content
        setActionContent([
            {
                position: document.getElementById('tutoring-start'),
                text: ACTION_TEXT_TUTORING,
                style: 'red',
                link: '/contact'
            },{
                position: document.getElementById('test-start'),
                text: ACTION_TEXT_TESTING,
                style: 'white',
                link: '/calendar'
            },{
                position: document.getElementById('advising-start'),
                text: ACTION_TEXT_ADVISE,
                style: 'red',
                link: '/contact'
            }
        ])
        
        setTimeout(() => {
            _setSmoothScrollOnLinkClick();
        }, 300)

        window.addEventListener('resize', setDisplayModes, { passive: true })

        // This is only used when the component is unmounted
        return function cleanup() {
            window.removeEventListener('resize', setDisplayModes, { passive: true })

            document.removeEventListener("scroll", animateListener, { passive: true });
        }
    }, [])

    useEffect(() => {
        if (!animStopped) {
            document.addEventListener("scroll", animateListener, { passive: true });
        } else {
            document.removeEventListener("scroll", animateListener, { passive: true });
        }
    }, [animStopped])

    function setDisplayModes(event) {
        if (window.innerWidth <= ACTION_DISPLAY) {
            setActionDisplay(true)
        } else {
            setActionDisplay(false)
        }

        if (window.innerWidth <= ANIM_STOP) {
            console.log("Animation stopped")
            setAnimStop(true)
        } else if(window.innerWidth > ANIM_STOP) {
            console.log("Animation started")
            setAnimStop(false)
        }
    }

    return (
        <Layout seo={{ title: "Home", url: "https://beckcollegeprep.com/" }}>
            {(actionContent !== null && !actionDisplay) && (
                <CallToAction content={actionContent}/>
            )}
            {(actionConsultation !== null && !actionDisplay) && (
                <CallToAction content={actionConsultation}/>
            )}
            <div style={{ position: 'relative', zIndex: 1 }}>
                <section id="student-carousel" className='sectionTop'>
                    <div style={{ marginTop: "10vh" }}>
                        <CollegeAcceptance students={students1} uniqueID={'1'}/>
                    </div>
                    <div style={{ marginTop: "47vh", marginBottom: "38vh" }}>
                        <div className="d-flex flex-wrap">
                            <div id='consultation-start' className={styles.introHeader + " anim-flag"} style={{ flex: "1", minWidth: "330px" }}>
                                <h3 className={styles.secondaryHeader}>BEGIN WITH A COMPLIMENTARY CONSULTATION</h3>
                            </div>
                            <div className='anim-flag' style={{ flex: "2", minWidth: "280px" }}>
                                <p className={styles.bigText}>We provide a <span style={{ color: '#424242', fontWeight: '400' }}>complimentary telephone conversation</span> to assess the student's goals and explain how Beck can help them achieve their aspirations.</p>
                            </div>
                        </div>
                        {actionDisplay && (
                            <div className='d-flex justify-content-center'>
                                <ActionButton link='/contact' text={ACTION_TEXT_CONSULT} style='red' />
                            </div>
                        )}
                    </div>
                </section>

                <section id="subject-tutoring" className='sectionTop'>
                    <div className='anim-flag'>
                        <h2 id='tutoring-start' style={{ textAlign: "center"}}>SUBJECT TUTORING</h2>
                    </div>

                    <div className='anim-flag'>
                        <h3 className={styles.secondaryHeader} style={{ textAlign: 'center', marginBottom: "6vh" }}>Strengthen Your Skills</h3>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <SubjectsContainer />
                    </div>

                    <div className='anim-flag' style={{ marginTop: "6vh" }}>
                        <p className="lead" style={{ fontSize: '1rem' }}>
                            We provide academic assistance for all the courses stated above for all programs, including,  but not limited to: End of Course Exams (<span style={{ fontWeight: '500', color: '#D73337' }}>EOCs</span>), International Baccalaureate (<span style={{ fontWeight: '500', color: '#D73337' }}>IB</span>), Cambridge International (<span style={{ fontWeight: '500', color: '#D73337' }}>Cambridge</span>), Advanced Placement (<span style={{ fontWeight: '500', color: '#D73337' }}>AP</span>), SAT (<span style={{ fontWeight: '500', color: '#D73337' }}>Subject Tests</span>), Honors, Dual Enrollment, Accelerated, and College Prep
                        </p>
                    </div>

                    {actionDisplay && (
                        <div className='d-flex justify-content-center'>
                            <ActionButton link='/contact' text={ACTION_TEXT_TUTORING} style='red' />
                        </div>
                    )}
                </section>

                <section id="sat-act" className='sectionTop' style={{ marginTop: '32vh' }}>
                    <div className='anim-flag'>
                        <h2 id='test-start' style={{ textAlign: "center"}}>SAT / ACT TEST PREPARATION</h2>
                    </div>

                    <div className="d-flex flex-column align-items-center">
                        <div className='anim-flag'>
                            <h3 className={styles.secondaryHeader} style={{ textAlign: 'center', marginTop: '10vh' }}>Our Personalized Method</h3>
                        </div>
                        <div style={{ marginBottom: "28vh" }}>
                            <BeckPlan />
                        </div>
                        <div className='anim-flag'>
                            <h3 className={styles.secondaryHeader} style={{ textAlign: 'center' }}>Beck's Students' Testing Success</h3>
                        </div>
                        <div className="d-flex justify-content-center">
                            <BigSpeed />
                        </div>
                        <div className={styles.graphicLabel + " d-flex flex-column"} style={{ marginTop: "9vh" }}>
                            <span style={{ color: '#888888' }}>* Results Not Guaranteed *</span>
                        </div>
                    </div>

                    {/* <div className='anim-flag' style={{ marginTop: "10px", marginBottom: "5px" }}>
                        <p style={{ marginBottom: "0px" }}>Students are given a complimentary ACT and/or SAT diagnostic exam to determine which exam is a better fit. Upon receiving the results of both tests, Beck will coordinate a meeting between the student and their parent. In the meeting, Beck will explain the student's areas of strength and deficiency (as presented on the diagnostic), the personalized tutoring and testing plan, and projected score improvements. Most importantly, the meeting serves to understand the student's personality to pair them with the Beck tutor that will motivate them to reach their SAT/ACT goal score.</p>
                    </div> */}

                    {actionDisplay && (
                        <div className='d-flex justify-content-center' style={{ marginTop: '4vh' }}>
                            <ActionButton link='/calendar' text={ACTION_TEXT_TESTING} style='white' />
                        </div>
                    )}
                </section>

                <section id="academic-college-advising" className='sectionTop' style={{ marginTop: '32vh' }}>
                    <div className='anim-flag'>
                        <h2 id='advising-start' style={{ textAlign: "center"}}>ACADEMIC / COLLEGE ADVISING</h2>
                    </div>
                    <div className='anim-flag'>
                        <h3 className={styles.secondaryHeader} style={{ textAlign: 'center', marginBottom: "5vh" }}>Guidance for the Future</h3>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <AdvisingContainer/>
                    </div>
                    {actionDisplay && (
                        <div className='d-flex justify-content-center'>
                            <ActionButton link='/contact' text={ACTION_TEXT_ADVISE} style='red' />
                        </div>
                    )}
                </section>

                <section id="college-acceptance" className='sectionTop' style={{ marginTop: '28vh', marginBottom: "32vh" }}>
                    <CollegeAcceptance students={students2} uniqueID={'2'} />
                </section>
            </div>
        </Layout>
    )
};

export default IndexPage;
