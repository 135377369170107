import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styles from './CallToAction.module.css'
import { IoIosArrowForward } from 'react-icons/io'

const CHANGE_CONTENT_OFFSET_FACTOR = .7

const componentStyle = {
    red: {
        background: '#D73337',
        circle: '#ffffff',
        text: '#ffffff',
        arrow: '#D73337'
    },
    white: {
        background: '#ffffff',
        circle: '#D73337',
        text: '#D73337',
        arrow: '#ffffff'
    }
}

/*
    content prop is structured as follows:
    [{
        position: Number,
        text: String,
        style: String ['red', 'white'],
        link: String
    }]
*/
const CallToAction = ({ content }) => {
    const [currentData, setCurrentData] = useState({
        text: content[0].text,
        style: componentStyle[content[0].style],
        link: content[0].link,
        bodyOffset: content[0].bodyOffset || 0
    })
    const [show, setShow] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleAppearance, { passive: true })

        return function cleanup() {
            window.removeEventListener('scroll', handleAppearance, { passive: true })
        };
    }, [])

    function handleAppearance() {
        // Handle showing or hiding call to action
        if ((content[0].position.getBoundingClientRect().top <= document.documentElement.clientHeight * (.3 + (content[0].offsetStart || 0))) 
        && (content[content.length - 1].position.getBoundingClientRect().top >= 0 - document.documentElement.clientHeight * (.6 - (content[content.length - 1].offsetEnd || 0)))) {
            setShow(true)
        } else {
            setShow(false)
        }

        // Handle setting content and style
        let selectedIndex = 0

        for (let i = 0; i < content.length; i++) {
            if (content[i].position.getBoundingClientRect().top <= (document.documentElement.clientHeight * CHANGE_CONTENT_OFFSET_FACTOR)) {
                selectedIndex = i
            }
        }

        setCurrentData({
            style: componentStyle[content[selectedIndex].style],
            text: content[selectedIndex].text,
            link: content[selectedIndex].link
        })
    }

    return(
        <Link to={currentData.link} style={{ textDecoration: 'none', display: 'block' }} className={`gtm-cta ${!show && styles.offScreen} ${styles.general}`}>
            <div className={styles.cardBody + " d-flex align-items-center"} style={{ backgroundColor: currentData.style.background, paddingRight: `${currentData.bodyOffset + 34}px` || '34px' }}>
                <div className={styles.circleContainer + " d-flex align-items-center"}>
                    <div className={styles.circle + " d-flex align-items-center justify-content-center"} style={{ backgroundColor: currentData.style.circle }}>
                        <div className={styles.arrow + " d-flex align-items-center"} style={{ color: currentData.style.arrow }}>
                            <IoIosArrowForward />
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center" style={{ height: '66px', width: '76px' }}>
                    <label className={styles.text} style={{ color: currentData.style.text }}>{currentData.text}</label>
                </div>
            </div>
        </Link>
    )
}

export default CallToAction;