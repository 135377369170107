import React from 'react'
import styles from './DynamicSpeed.module.css'

const RADIUS = 120.463 // Don't change, seriously. Someone's getting hurt if this gets changed.

// Data property takes an object structured as follows:
// const data = {
//     circles: [
//         {
//             fractionFill: number between 0 and 1
//         }
//     ],
//     transitionTime: positive number
// }
const DynamicSpeed = ({ uniqueId, size, data, centerContent, showCenterContent, gradientColor }) => {
    return (
        <div className={"d-flex flex-column" + " " + styles.circleContainer} style={{ height: size, width: size }}>
            { data.circles.map((circle, i) => {
                // Calculate target
                const circumference = 2 * RADIUS * 3.14 // circumference = 2 * pi * r
                const calculatedFill = 1 - circle.fractionFill
                const target = circumference * calculatedFill

                return (
                    <div key={"circle" + i} style={{ position: "absolute", zIndex: i }}>
                        {centerContent && (
                            <div style={{ height: size, width: size, position: "absolute", transition: ".6s all ease-out" }} className={"d-flex justify-content-center align-items-center " + (!showCenterContent && styles.hide)}>
                                <div className="d-flex flex-column align-items-center">
                                    {centerContent}
                                </div>
                            </div>
                        )}
                        <svg className={styles.circleSVG} height={size} viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle id={"circleGraphic" + i} style={{ transition: `all ${data.transitionTime}s ease-in-out` }} cx="146.537" cy="146.585" r={RADIUS} stroke={`url(#paint0_linear${uniqueId})`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="20" strokeDasharray={circumference} strokeDashoffset={target} />
                            <circle cx="146.537" cy="146.585" r="144.037" stroke="#C5C5C5" strokeWidth="5"/>
                            <defs>
                                <linearGradient id={"paint0_linear" + uniqueId} x1="23" y1="85.5" x2="262" y2="184" gradientUnits="userSpaceOnUse">
                                    <stop stopColor={gradientColor?.start || "#ffffff"}/>
                                    <stop offset="1" stopColor={gradientColor?.end || "#707070"}/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                )
            })}
        </div>
    )
}

export default DynamicSpeed