import React, { useState,useEffect } from 'react';
import calc from "../../../images/subjects/calc.png"
import flask from "../../../images/subjects/flask.png"
import pencils from "../../../images/subjects/pencils.png"
import pencilsAlt from "../../../images/subjects/accordion/pencils.png"
import globe from "../../../images/subjects/globe.png"
import globeAlt from "../../../images/subjects/accordion/globe.png"
import { animateStaggered } from '../../../utility/general'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import styles from "./SubjectsContainer.module.css"
import { IoIosArrowForward } from "react-icons/io";

const VERTICAL = 1310;
const ACCORDION_WIDTH = 750;
const SUBJECTS_SOCIAL = ['Art History', 'Psychology', 'Economics', 'World History', 'US History', 'Government', 'European History']
const SUBJECTS_MATH = ['Statistics', 'Pre-Algebra', 'Algebra I & II', 'Pre-Calculus', 'Calculus AB/BC', 'Geometry', 'College Algebra']
const SUBJECTS_LANGUAGE = ['English I', 'English II', 'English III', 'American Literature', 'American Language', 'World Literature', 'World Language']
const SUBJECTS_SCIENCE = ['Chemistry', 'Biology', 'Marine Biology', 'Physics I', 'Physics II', 'Computer Science', 'Environmental']

const subjects = [{
    name: "MATH",
    subs: SUBJECTS_MATH,
    image: calc
},{
    name: "LANGUAGE ARTS",
    subs: SUBJECTS_LANGUAGE,
    image: pencils,
    imageAlt: pencilsAlt
},{
    name: "SCIENCE",
    subs: SUBJECTS_SCIENCE,
    image: flask
},{
    name: "SOCIAL SCIENCE",
    subs: SUBJECTS_SOCIAL,
    image: globe,
    imageAlt: globeAlt
}]

const SubjectsContainer = () => {
    const [accordion, setAccordion] = useState(false)
    const [vertical, setVertical] = useState(false)
    const [accordionKey, setAccordionKey] = useState(0)

    useEffect(() => {
        // Initialize display mode (accordion)
        setDisplayMode();
        // Initialize display mode (vertical)
        setDisplayVertical();

        const animatefunction = () => { animateStaggered('subjects', 'animate-subjects') };

        document.addEventListener("scroll", animatefunction, { passive: true });
        window.addEventListener('resize', setDisplayMode, { passive: true })
        window.addEventListener('resize', setDisplayVertical, { passive: true })

        // This is only used when the components is unmounted
        return function cleanup() {
            document.removeEventListener("scroll", animatefunction, { passive: true });
            window.removeEventListener('resize', setDisplayMode, { passive: true })
            window.removeEventListener('resize', setDisplayVertical, { passive: true })
        };
    }, [])

    function setDisplayMode(event) {
        if (document.documentElement.clientWidth <= ACCORDION_WIDTH) {
            setAccordion(true)
        } else {
            setAccordion(false)
        }
    }

    function setDisplayVertical(event) {
        if (window.innerWidth <= VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
    }

    return (
        <div id='subjects' className="w-100">
            {!accordion ? (
                <>
                    {vertical ? (
                        <div className={`${styles.containerMain} d-flex flex-column align-items-center`}>
                            <div className="d-flex justify-content-evenly">
                                {subjects.map((subject, i) => {
                                    return (
                                        <div key={'subject' + i} style={{padding: "0", animationDelay: `${i * 0.15}s`}} className={`${styles.card} d-flex flex-column align-items-center animate-subjects`}>
                                            <div style={{
                                                backgroundImage: `url(${subject.image})`,
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                border: 'hidden'
                                            }}
                                            className={styles.image}/>
                                            <div className={styles.listHeading}>
                                                {subject.name}
                                            </div>
                                            <div className="d-flex flex-column">
                                                {subject.subs.map((sub, i) => {
                                                    return (
                                                        <div key={sub + i} className="d-flex align-items-start justify-content-center">
                                                            <div className={styles.listItem}>{sub}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ):(
                        <div className={`${styles.containerMain} d-flex justify-content-between`}>
                                {subjects.map((subject, i) => {
                                    return (
                                        <div style={{padding: "0", animationDelay: `${i * 0.15}s`}} className={`d-flex flex-column align-items-center animate-subjects`} key={i}>
                                            <div style={{
                                                backgroundImage: `url(${subject.image})`,
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                border: 'hidden'
                                            }}
                                            className={styles.image}/>
                                            <div className={styles.listHeading}>
                                                {subject.name}
                                            </div>
                                            <div className="d-flex flex-column">
                                                {subject.subs.map((sub, i) => {
                                                    return (
                                                        <div key={sub + i} className="d-flex align-items-start justify-content-center">
                                                            <div className={styles.listItem}>{sub}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    )}
                </>
            ) : (
                <Accordion className={styles.accordionMain} activeKey={`${accordionKey}`} defaultActiveKey="0">
                    {subjects.map((subject, i) => {
                        return (
                            <Card className={styles.accordionCard} key={subject.name}>
                                <Accordion.Toggle as={Card.Header} className={`${styles.accordionHeader} d-flex flex-column align-items-center`} onClick={() => {
                                    if (i === accordionKey) {
                                        setAccordionKey(-1)
                                    } else {
                                        setAccordionKey(i)
                                    }
                                }}>
                                    <div style={{
                                        backgroundImage: `url(${subject.imageAlt || subject.image})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        border: 'hidden'
                                    }}
                                    className={styles.image}/>
                                    <div className={styles.accordionHeaderText + " d-flex align-items-center"} style={{ marginLeft: '12px' }}>
                                        <div style={{ marginTop: '6px', marginRight: '4px' }}>
                                            {subject.name}
                                        </div>
                                        <div className={styles.carrot + " " + (i === accordionKey && styles.carrotSelected)}>
                                            <IoIosArrowForward />
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={`${i}`}>
                                <Card.Body className={styles.accordionBody + " d-flex justify-content-center"}>
                                    <div className="d-flex flex-column">
                                        {subject.subs.map((sub, i) => {
                                            return (
                                                <div key={sub + i} style={{ textAlign: 'center' }}>
                                                    <span style={{ color: "#6b6b6b" }}>{sub}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )
                    })}
                </Accordion>
            )}
        </div>
    )
}

export default SubjectsContainer;